<template>
  <div class="not-found-page">
    <div class="not-found" :style="{ backgroundImage: `url(${bg})` }">
      <button @click="backToHome()">返回首页</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bg: require('@/assets/images/404-bg.png')
    }
  },

  methods: {
    backToHome() {
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>
.not-found-page {
  height: 100%;

  .not-found {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    button {
      width: 346px;
      height: 64px;
      line-height: 64px;
      background: var(--color-primary);
      border-radius: 12px;
      letter-spacing: 2px;
      font-size: 25px;
      font-weight: 400;
      color: $white-color;
      border: none;
      outline: none;
      position: absolute;
      left: 50%;
      bottom: 31%;
      transform: translateX(-50%);
      transition: background 0.1s;

      &:hover {
        background: var(--color-primary);
      }

      &:active {
        background: var(--color-primary);
      }
    }
  }
}
</style>
